import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const isSync = [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'payment_audit_status',
    },
    {
      label: '费用类型',
      prop: 'feeType',
      propDesc: 'feeTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'ar_fee_type',
    },
    {
      label: '日期',
      prop: 'arPayDate',
      minWidth: 120,
      value: [],
      type: 'time',
      filterProp: ['arPayDateStartDate', 'arPayDateEndDate'],
    },
    {
      label: '金额(人民币)',
      prop: 'amountRmb',
      propDesc: 'amountRmbDesc',
      minWidth: 150,
      value: [],
      type: 'number',
      filterProp: ['amountRmbMinVal', 'amountRmbMaxVal'],
    },
    {
      label: '金额(交易币种)',
      prop: 'arAmountTc',
      propDesc: 'arAmountTcDesc',
      minWidth: 170,
      value: [],
      type: 'number',
      filterProp: ['arAmountTcMinVal', 'arAmountTcMaxVal'],
    },
    {
      label: '交易币种',
      prop: 'arTradeCurrency',
      propDesc: 'arTradeCurrencyDesc',
      minWidth: 130,
      type: 'multiSelect',
      options: 'trade_currency',
      value: [],
    },
    {
      label: '汇率',
      prop: 'arExchangeRate',
      propDesc: 'arExchangeRate',
      minWidth: 120,
      value: [],
      type: 'number',
      filterProp: ['arExchangeRateMinVal', 'arExchangeRateMaxVal'],
    },
    {
      label: '结算金额(交易币种)',
      prop: 'arSettleAmountTc',
      propDesc: 'arSettleAmountTcDesc',
      minWidth: 200,
      value: [],
      type: 'number',
      filterProp: ['arSettleAmountTcMinVal', 'arSettleAmountTcMaxVal'],
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 180,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '是否同步到应付',
      prop: 'isSyncToAp',
      propDesc: 'isSyncToApDesc',
      type: 'signSelect',
      options: isSync,
      value: '',
      minWidth: 160,
    },
    {
      label: '凭据文件',
      prop: 'consigneeShortName',
      minWidth: 90,
      value: '',
      type: 'input', // 筛选类型 input 输入项
      isSort: false,
      isFilter: false,
      component: defineComponent({
        template: `
        <tableFileContent :readOnly="true"
        :fileUrlsArray="row.fileVoList"
        key-name="orderArapFileName"
        key-url="orderArapFileUrl"
        key-id="orderArapFileId"
      ></tableFileContent>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 120,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 120,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 180,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
  ];
  return { columnList, tableRef };
};
